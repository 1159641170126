import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/vsts/work/1/s/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "how-do-i-reset-the-application"
    }}>{`How do I reset the application?`}</h1>
    <p>{`The application is running in the browser. To completely reset the it you have to clear the Browser cache and stored data.`}</p>
    <h2 {...{
      "id": "how-do-i-reset-the-browser-cache"
    }}>{`How do I reset the browser cache?`}</h2>
    <h4 {...{
      "id": "in-chrome-the-supported-browser-for-vpm"
    }}>{`In Chrome (the supported browser for VPM)`}</h4>
    <p>{`You can reset the cache by opening this link: `}<a parentName="p" {...{
        "href": "chrome://settings/clearBrowserData"
      }}>{`chrome://settings/clearBrowserData`}</a></p>
    <img src="/public/202002-reset-cache.png" style={{
      "maxWidth": "500px",
      "width": "90%",
      "marginLeft": "auto",
      "marginRight": "auto",
      "display": "block"
    }} />
    <p>{`You may retain your history, but you have to delete both cookies and cached files.`}</p>
    <h4 {...{
      "id": "in-other-browsers"
    }}>{`In other browsers`}</h4>
    <p>{`Please refer to the browser manual to find out how to reset the browser cache.`}</p>
    <h2 {...{
      "id": "implications-of-resetting-the-cache"
    }}>{`Implications of resetting the cache?`}</h2>
    <p>{`Please note that resetting the cache results in a full download the entire including the data stored in it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      